import { useIsFeatureActive } from '@sortlist-frontend/feature-flags';
import { TFunction, Trans } from '@sortlist-frontend/translation/server-only';
import { useRef } from 'react';

import { CompanyLogos } from '_components/Companies/CompanyLogos';
import { NavigationData } from '_components/layout/Layout';
import { CompanyType } from '_data/companies';
import { Page } from '_types/public-api';

import { BriefingHeroCta } from './BriefingHeroCta';

type Props = {
  className?: string;
  companies?: CompanyType[];
  page: Page;
  t: TFunction;
  appRouter?: boolean;
  navigationData: NavigationData;
};

export const Hero = (props: Props) => {
  const { page, t, companies, appRouter = false, navigationData } = props;

  const { cta, cta_variant: ctaVariant, ratio } = page.data.attributes?.page_options?.hero ?? {};

  const ref = useRef<HTMLDivElement>(null);

  const { isActive: heroCtaActive } = useIsFeatureActive('hero-cta', {
    triggerEvent: true,
    viewEventRef: ref,
  });

  const hasFullViewportHero = ratio === 1 || ratio == null;

  return (
    <div
      className={`${hasFullViewportHero ? 'hero-full-viewport' : ''} hero bg-secondary-900 layout-column layout-align-start-center relative`}
      ref={ref}
      style={{
        maxHeight: ratio != null ? `${ratio * 100}%` : 'auto',
      }}>
      <div className="layout-column layout-align-center-center flex-grow relative container-lg p-64 p-xs-16">
        <div className="text-center">
          <h1 className="hero-title text-neutral-100 bold">{page?.data.attributes.title.title}</h1>
          <p className="hero-subtitle text-neutral-100 h6 mt-24 pt-xs-16">{page?.data.attributes.title.tagline}</p>
        </div>
        <div className="pt-xs-40 layout-column layout-align-center-center">
          <BriefingHeroCta
            label={
              heroCtaActive ? ctaVariant ?? t('longtail:header.findMyAgency') : cta ?? t('longtail:header.findMyAgency')
            }
            navigationData={navigationData}
          />
          <div className="text-secondary-300 small text-center pt-4 mb-40">
            <Trans i18nKey="longtail:header.takes3Min_1" t={t} appRouter={appRouter}>
              Takes 3 min. <b>100% free</b>
            </Trans>
          </div>
        </div>
        <CompanyLogos companies={companies} />
      </div>
    </div>
  );
};
